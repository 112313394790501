import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';

import '@shoelace-style/shoelace/dist/components/card/card.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';

import { styles } from '../styles/shared-styles';



@customElement('app-home')
export class AppHome extends LitElement {

	// For more information on using properties and state in lit
	// check out this link https://lit.dev/docs/components/properties/
	@property() message = 'Valotalive extension launcher pwa';

	static styles = [
		styles,
		css`
    #welcomeBar {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    #welcomeCard,
    #infoCard {
      padding: 18px;
      padding-top: 0px;
    }

    sl-card::part(footer) {
      display: flex;
      justify-content: flex-end;
    }

    @media(min-width: 750px) {
      sl-card {
        width: 70vw;
      }
    }


    @media (horizontal-viewport-segments: 2) {
      #welcomeBar {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
      }

      #welcomeCard {
        margin-right: 64px;
      }
    }
  `];

	async firstUpdated() {
		// this method is a lifecycle even in lit
		// for more info check out the lit docs https://lit.dev/docs/components/lifecycle/
	}

	share() {
		if ((navigator as any).share) {
			(navigator as any).share({
				title: 'PWABuilder pwa-starter',
				text: 'Check out the PWABuilder pwa-starter!',
				url: 'https://github.com/pwa-builder/pwa-starter',
			});
		}
	}

	extensionLaunch() {
		// @ts-ignore
		chrome.runtime.sendMessage('peigilijgmgijmphlnlnhjmjckdehhag', {action: "pwa_launch"},
				function (response: any) {
					if (typeof response === 'undefined' || response.status !== "ok") {
						console.warn("nok",response);
					}
					else{
						console.log(response);
					}
				});
	}

	render() {
		return html`
      <app-header></app-header>

      <main>
        <div id="welcomeBar">
          <sl-card id="welcomeCard">
            <div slot="header">
              <h2>${this.message}</h2>
            </div>
          </sl-card>

          <sl-card id="infoCard">
            <h2>Technology Used</h2>

            <ul>
              <li>
                <a href="https://www.typescriptlang.org/">TypeScript</a>
              </li>

              <li>
                <a href="https://lit.dev">lit</a>
              </li>

              <li>
                <a href="https://shoelace.style/">Shoelace</a>
              </li>

              <li>
                <a href="https://github.com/thepassle/app-tools/blob/master/router/README.md"
                  >App Tools Router</a>
              </li>
            </ul>
          </sl-card>

          <sl-button variant="primary" @click="${this.extensionLaunch}">Launch the extension</sl-button>
        </div>
      </main>
    `;
	}
}
